// Variables
@import "variables";

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

@import "~cookieconsent/build/cookieconsent.min";

@import "loader";
@import "entertainment";
@import "cookie-consent-bar";

@import "../../general/css/prism.css";

[x-cloak] { display: none !important; }
