#awardHolder {
    visibility: hidden;

    &.show {
        visibility: visible;
    }
}

.gamecards-wrapper {
    position: relative;
    width: 100%;
    height: 12.5rem;

    @media (max-width: 575px) {
        height: 8.75rem;
    }

    .gamecard {
        margin: 0 1rem;
        cursor: pointer;
        display: block;
        width: 120px;
        height: 168px;
        perspective: 18.75rem;
        -webkit-transition: transform 150ms ease-in-out;
        -moz-transition: transform 150ms ease-in-out;
        -o-transition: transform 150ms ease-in-out;
        transition: transform 150ms ease-in-out;

        @media (max-width: 575px) {
            margin-left: 5px;
            margin-right: 5px;
            width: 84px;
            height: 118px;
        }

        &:not(.disabled):not(.selected):hover {
            transform: scale(1.025) translateY(-5px);
        }

        &.disabled {
            .back {
                cursor: default;
                filter: gray; /* IE6-9 */
                -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
                filter: grayscale(1);
                opacity: .7;
                transform: scale(.9);
            }
        }

        &.gamecard.selected {
            .back {
                transform: rotateY(180deg);
            }

            .front {
                transform: rotateY(360deg);
            }
        }

        .front {
            transform: rotateY(180deg);
        }

        .back {
            background: url('../images/scratch/gamecard.jpg') no-repeat center center;
        }

        .front,
        .back {
            position: absolute;
            width: 100%;
            height: 100%;
            transition: transform 1s;
            backface-visibility: hidden;
        }
    }
}

@media (max-width: 575px) {
    .entertainment-title {
        font-size: 1.25rem;
    }
}
