#loader-block{
    position: fixed;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgb(243, 244, 246);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
        display: inline-block;
        border: 3px solid darken(rgb(243, 244, 246), 10%);
        border-radius: 50%;
        border-top: 3px solid #27c282;
        border-bottom: 3px solid #27c282;
        width: 80px;
        height: 80px;
        -webkit-animation: spin 3s linear infinite;
        animation: spin 3s linear infinite;
    }

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}
